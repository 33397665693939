import React from 'react'
import { Root } from 'templates/layout'

export default function ArchitecturalVR() {
	return (
		<Root mainFullWidth desktopCtaVariant="hidden">
			<section className="px-screen-step py-screen-step-2">
				<h1 className="my-screen-step w-full lg:ml-screen-step-6 lg:w-screen-step-12">
					Architectural Virtual Reality
				</h1>

				<div className="mx-auto mt-screen-step-2 lg:w-screen-step-26">
					<iframe
						className="aspect-[16/9] w-full"
						title="VR 360"
						allowFullScreen
						src="https://cuub-vr.netlify.app/360-lite-oct-4/"
					/>
				</div>
			</section>
		</Root>
	)
}
